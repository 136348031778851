@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --main-color: #1d2c5c;
}



.App {
  background-color: white;
  text-align: center;
  padding: 0px 50vh;

  @media (max-width: 1700px) {
    padding: 0px 20vh;
  }

  @media (max-width: 1000px) {
    padding: 0px 5vh;
  }

  p {
    font-size: 14px;
  }

  h1 {
    color: var(--main-color);
    margin: 2px;
  }

  h2 {
    color: var(--main-color);
    margin: 2px;
  }

  * {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
  }

}

@import url('https://fonts.googleapis.com/css2?family=Signika+Negative:wght@300..700&display=swap');

.title-font {
  font-family: "Signika Negative", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-size: 6vh;

  @media (max-width: 440px) {
    font-size: 4vh;
  }

  @media (max-width: 360px) {
    font-size: 2vh;
  }

}

.top-ribbon {
  height: 10px;
  background-color: var(--main-color);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    height: 180px;
    width: 180px;
    border-radius: 100px;
    border-style: solid;
    border-width: 2px;
    border-color: var(--main-color);
    /* box-shadow: 0px 0px 10px 2px rgb(29, 155, 240); */
  }
}

.App-header {
  padding: 2vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;

  p {
    margin: 1vh 0vh;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.socials-wrapper {
  display: grid;
  grid-template-columns: auto auto auto auto;
  align-items: center;
  justify-items: center;
}

.section-div {
  color: black;
  margin-top: 1vh;
  margin-bottom: 1vh;
  border-width: 1px;

  code {
    font-weight: bold;
  }
}

.css-button-sliding-to-left--green {
  margin: 0px 10px;
  color: white;
  padding: 7px 7px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-flex;
  outline: none;
  border-radius: 20px;
  z-index: 0;
  background: white;
  overflow: hidden;
  border: 1px solid var(--main-color);
  color: var(--main-color);
  justify-items: center;
  align-content: center;
  align-items: center;
  text-decoration: none;
}

.css-button-sliding-to-left--green:hover {
  color: white;
}

.css-button-sliding-to-left--green:hover:after {
  width: 100%;
}

.css-button-sliding-to-left--green:after {
  content: "";
  position: absolute;
  z-index: -1;
  transition: all 0.3s ease;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background: var(--main-color);
}

.me {
  display: grid;
  color: black;

  p {
    text-align: left
  }
}

.portfolio-wrapper {
  display: grid;
  justify-content: center;
  color: black;
  padding-bottom: 120px;
}

.projects-wrapper {
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: auto;

  @media (max-width: 1600px) {
    display: grid;
    grid-template-columns: auto;
  }

  @media (max-width: 900px) {
    display: grid;
    grid-template-columns: auto;
  }

  ;

  :hover,
  :active {
    color: var(--main-color);
  }

  ;
}

.project-image {
  width: 200px;
  height: 150px;
}

.project {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  text-align: center;
  width: 40%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 1600px) {
    width: 90%;
  }

  @media (max-width: 900px) {
    width: 90%;
  }

  a:hover {
    color: white
  }
}

.image {
  width: 300px;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
}

.projectFrame {
  width: 300px;
  height: 250px;
  border: 0px;
}


.project:hover {
  /* margin-bottom: 20px;
  margin-top: 20px; */
  transition: 0.8s ease-in-out;

}

.project-link {
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: auto;
}

.project-link:hover .project-img-overlay {
  visibility: visible;
  display: inline;
  color: white;
  transition: visibility 0s ease;
  word-wrap: break-word;
  overflow-wrap: break-word;

}

.project-img-overlay {
  /* position: absolute; */
  background-color: var(--main-color);
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  text-align: center;
  visibility: collapse;
  display: none;
  transition: visibility 2s ease-in-out;
  max-width: 300px;
}

.stack-section {
  margin-bottom: 10px;
}

.stack-wrapper {
  display: grid;
  margin: 1em;
  grid-template-columns: auto auto auto auto auto auto;

  @media (max-width: 1700px) {
    grid-template-columns: auto auto auto auto auto auto;
  }

  @media (max-width: 1400px) {
    grid-template-columns: auto auto auto auto auto;
  }

  @media (max-width: 950px) {
    grid-template-columns: auto auto auto;
  }

  @media (max-width: 700px) {
    grid-template-columns: auto auto;
  }

}

.stack-element {
  color: black;
  padding: 10px;

  p {
    margin: 5px;
  }

  svg {
    width: 45px;
    height: 45px;
    padding: 5px 0px;

    @media (max-width: 950px) {
      width: 25px;
      height: 25px;
      padding: 1px;
    }

  }

  svg:hover,
  :active {
    background-color: var(--main-color);
    box-shadow: 0px 0px 2px 5px var(--main-color);
    color: white;
    border-radius: 10px;
    transition: all 0.28s ease-in-out;

  }
}

.text-link {
  a {
    color: white;
  }

  a:hover {
    color: var(--main-color);
  }
}

.footer-wrapper {
  display: grid;
  grid-template-columns: auto;
  justify-items: center;
  background-color: var(--main-color);
  bottom: 0;
  width: 100%;
  color: white;

  .copyright-text {
    margin: 0px;
    font-size: 12px;
  }
}

.footer-social {
  display: grid;
  grid-template-columns: auto;
  justify-items: center;
  padding-top: 30px;
  padding-bottom: 30px;

  h3 {
    margin-top: 0px;
  }

}

.working-on-wrapper {
  display: grid;
  border-style: solid;
  border-radius: 8px;
  border: 1px solid #ddd;
  margin-left: 30vh;
  margin-right: 30vh;
  margin-top: 1vh;
  margin-bottom: 1vh;
  padding: 8px;

  /* @media (max-width: 1700px) {
    margin-left: 25vh;
    margin-right: 25vh;
  } */

  @media (max-width: 1200px) {
    margin-left: 15vh;
    margin-right: 15vh;
  }

  @media (max-width: 1000px) {
    margin-left: 10vh;
    margin-right: 10vh;
  }

  @media (max-width: 800px) {
    margin-left: 8vh;
    margin-right: 8vh;
  }

  @media (max-width: 600px) {
    margin-left: 0vh;
    margin-right: 0vh;
  }

}

.working-on-header {
  display: grid;
  width: fit-content;
  grid-template-columns: auto auto;
  align-items: center;
  justify-items: center;

  img {
    width: 30px;
    height: 30px;
  }

  p {
    margin: 1px;
    ;
  }

}

.working-on-section {
  margin-top: 1rem;
  margin-bottom: 2rem;

}

.working-on-image-div {
  width: fit-content;

  img {
    margin: 5px;
  }
}

.working-on-header-text {
  display: grid;
  align-items: center;
  justify-items: initial;
  text-align: left;

  p {
    font-size: 12px;
  }
}

.working-on-title {
  font-weight: bold;
}

.working-on-description {
  text-align: left;
  font-size: 11px;

  ul {
    list-style: none;
    padding: 0px;
  }
}

.working-on-element-separator {
  border-style: solid;
  border-width: 1px 0px 0px 0px;
  color: var(--main-color);
}